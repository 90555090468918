import React, { Component } from "react"


import IronCAPBountyContest from "../../components/sections/bountyContest/ironCAPBountyContest"

class IronCAPBountyContestCt extends Component {
  constructor(props) {
    super(props)

    this.state = {
       lang:"ct"
    }
      }

  render() {
    return (
     
		<IronCAPBountyContest 
		lang={this.state.lang}
		/>
     
    )
  }
}

export default IronCAPBountyContestCt
